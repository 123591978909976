.container-main {
  margin-top: 35px;
  margin-bottom: 35px;
}

#logo {
  height: 125px;
}

.promo {
  .text {
    text-align: justify;
  }
}

.promo {
  img {
    height: 15px;
  }
}

figure.highlight {
  text-align: left;
  margin: 0;
}

figure.highlight {
  pre {
    background-color: #FAF9F8;
  }
}

.social-icons {
  a {
    margin-right: 10px;
  }
}

.breadcrumb {
  background-color: #FAF9F8;
  padding: 10px;

  .is-active {
    font-weight: bold;
  }
}

.breadcrumb-before-content {
  padding-left: 12px;
}
